import { createMuiTheme } from "@material-ui/core/styles";
const MaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#08b3f8",
    },
  },
});

export default MaterialTheme;
