import { GameActionTypes } from "./game.types";

const INITIAL_STATE = {
  game: null,
};

const gameReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GameActionTypes.SET_GAME:
      return {
        ...state,
        game: action.payload,
      };

    case GameActionTypes.SET_SCORE:
      const scoreInfo = action.payload;
      return {
        ...state,
        [`game.scores.${scoreInfo.playerId}.score`]: scoreInfo.updatedScore,
      };

    default:
      return state;
  }
};

export default gameReducer;
