import { CardsActionTypes } from "./cards.types";

const INITIAL_STATE = {
  cards: [],
};

const cardsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CardsActionTypes.SET_CARDS:
      return {
        ...state,
        cards: action.payload,
      };

    default:
      return state;
  }
};

export default cardsReducer;
