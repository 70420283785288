import { LoginActionTypes } from "./login.types";

const INITIAL_STATE = {
  login: false,
  loginMessage: "",
};

const loginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoginActionTypes.SET_LOGIN:
      return {
        ...state,
        login: action.payload,
      };
    case LoginActionTypes.SET_LOGIN_MESSAGE:
      return {
        ...state,
        loginMessage: action.payload,
      };

    default:
      return state;
  }
};

export default loginReducer;
