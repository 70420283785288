import { LoginActionTypes } from "./login.types";
export const setLogin = (login) => (dispatch) => {
  dispatch({
    type: LoginActionTypes.SET_LOGIN,
    payload: login,
  });
};

export const setLoginMessage = (message) => (dispatch) => {
  dispatch({ type: LoginActionTypes.SET_LOGIN_MESSAGE, payload: message });
};
