import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  authenticated: false,
  loading: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
        loading: false,
      };

    case UserActionTypes.SET_UNAUTHENTICATED:
      return INITIAL_STATE;

    case UserActionTypes.SET_USER:
      return {
        ...action.payload,
        authenticated: true,
        loading: false,
      };

    case UserActionTypes.SET_USER_GAME_SETTINGS:
      return {
        ...state,
        gameSettings: { ...action.payload },
      };

    case UserActionTypes.LOADING_USER:
      return {
        ...state,
        loading: action.payload || true,
      };

    default:
      return state;
  }
};

export default userReducer;
