import { combineReducers } from "redux";

import userReducer from "./user/user.reducer";
import loadingReducer from "./loading/loading.reducer";
import gameReducer from "./game/game.reducer";
import cardsReducer from "./cards/cards.reducer";
import loginReducer from "./login/login.reducer";
import serviceWorkerReducer from "./serviceWorker/serviceWorker.reducer";

export default combineReducers({
  userReducer: userReducer,
  loadingReducer: loadingReducer,
  gameReducer: gameReducer,
  cardsReducer: cardsReducer,
  loginReducer: loginReducer,
  serviceWorkerReducer: serviceWorkerReducer,
});
