import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import config from "../environment/environment";
import store from "../redux/store";
import { LoginActionTypes } from "../redux/login/login.types";

let firebaseApp;
if (firebase.apps.length > 0) {
  firebaseApp = firebase.apps[0];
} else {
  firebaseApp = firebase.initializeApp(config.firebaseConfig);
}
firebaseApp.analytics();
const db = firebaseApp.firestore();
const storage = firebaseApp.storage();
const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();
// TODO: only run if user just logs out.
const signInWithGoogle = () => {
  if (localStorage.getItem("forceLoggedOut")) {
    googleProvider.setCustomParameters({ prompt: "select_account" });
  }
  return auth
    .signInWithRedirect(googleProvider)
    .catch((err) => {
      store.dispatch({
        type: LoginActionTypes.SET_LOGIN_MESSAGE,
        payload: err.message,
      });
    })
    .finally(() => {
      localStorage.setItem("forceLoggedOut", false);
    });
};

const facebookProvider = new firebase.auth.FacebookAuthProvider();
const signInWithFacebook = () => {
  if (localStorage.getItem("forceLoggedOut")) {
    facebookProvider.setCustomParameters({
      auth_type: "reauthenticate",
    });
  }
  auth
    .signInWithRedirect(facebookProvider)
    .catch((err) => {
      store.dispatch({
        type: LoginActionTypes.SET_LOGIN_MESSAGE,
        payload: err.message,
      });
    })
    .finally(() => {
      localStorage.setItem("forceLoggedOut", false);
    });
};

export { auth, storage, signInWithGoogle, signInWithFacebook };
export default db;
