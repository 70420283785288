import { ServiceWorkerActionTypes } from "./serviceWorker.types";

const INITIAL_STATE = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
  serviceWorkerOffline: false,
};

const serviceWorkerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ServiceWorkerActionTypes.SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: action.payload !== null,
        serviceWorkerRegistration: action.payload,
        serviceWorkerOffline: false,
      };
    case ServiceWorkerActionTypes.SW_OFFLINE:
      return {
        ...state,
        serviceWorkerUpdated: false,
        serviceWorkerOffline: true,
        serviceWorkerRegistration: null,
      };

    default:
      return state;
  }
};

export default serviceWorkerReducer;
