const dev = {
	production: false,
	APP_VERSION: "2.2.1",
	base_url: "http://localhost:3000",
	firebaseConfig: {
		apiKey: "AIzaSyCJluDAwBR7M_l1Q88IfwjKhPgiokoH7S4",
		authDomain: "doinkstaging-b9b67.firebaseapp.com",
		projectId: "doinkstaging-b9b67",
		storageBucket: "doinkstaging-b9b67.appspot.com",
		messagingSenderId: "833252677857",
		appId: "1:833252677857:web:d873d13cf4f281e82effa0",
		measurementId: "G-MZMD92X24R",
	},
	apiURL: "https://us-central1-doinkstaging-b9b67.cloudfunctions.net/app",
	stripePublishKey:
		"pk_test_51IB9WHHKh8x5VHVZ6Cx6RhOqlt2SMrAWPn2f3CEZtSK1adzQpKcp2TY4lYmRgTJRYsOw4NNYgOeTZiIc5806fxPr00OScyK8OR",
};

const beta = {
	production: false,
	APP_VERSION: "2.2.1",
	base_url: "https://doinkbeta.web.app",
	firebaseConfig: {
		apiKey: "AIzaSyCpcwXS3apMzXhMTL7Um7QWFYUy9y4PMHo",
		authDomain: "doinkbeta.firebaseapp.com",
		projectId: "doinkbeta",
		storageBucket: "doinkbeta.appspot.com",
		messagingSenderId: "826730197002",
		appId: "1:826730197002:web:8297460d116e28f57b6ff3",
		measurementId: "G-213YDN6LD6",
	},
	apiURL: "https://us-central1-doinkbeta.cloudfunctions.net/app",
	stripePublishKey:
		"pk_test_51IB9WHHKh8x5VHVZ6Cx6RhOqlt2SMrAWPn2f3CEZtSK1adzQpKcp2TY4lYmRgTJRYsOw4NNYgOeTZiIc5806fxPr00OScyK8OR",
};

const prod = {
	production: true,
	APP_VERSION: "2.2.1",
	base_url: "https://app.doinkdiscgolf.com",
	firebaseConfig: {
		apiKey: "AIzaSyDTcIsfF8tCCrzMr-Wsq_TpSweZY8PepAA",
		authDomain: "doink-672a6.firebaseapp.com",
		projectId: "doink-672a6",
		storageBucket: "doink-672a6.appspot.com",
		messagingSenderId: "1083191432687",
		appId: "1:1083191432687:web:4a81b42981807327d5121a",
		measurementId: "G-2CXBH47W1C",
	},
	apiURL: "https://us-central1-doink-672a6.cloudfunctions.net/app",
	stripePublishKey:
		"pk_live_51IB9WHHKh8x5VHVZSmZAXJsa2Se49xXB2SvYCwdolFTecwrluxBehdnbT0wMFr9fXVRPJAeBvFpfaSIcnRrNRGKX0049Tu4atm",
};

const projectStage = process.env.REACT_APP_STAGE;
let config;
if (projectStage === "production") {
	config = prod;
} else if (projectStage === "beta") {
	config = beta;
} else {
	config = dev;
}

export default config;
