import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { auth } from "../util/firebase";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../redux/user/user.actions";
import DoinkIcon from "../images/Doink_Icon.png";
import "./DoinkNavbar.css";
import Rules from "../pages/Rules";

function DoinkNavbar({ user, logoutUser }) {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [showRules, setShowRules] = useState(false);

  const signOut = () => {
    setExpanded(false);
    auth
      .signOut()
      .catch((err) => console.error(err))
      .finally(() => {
        console.log("Setting unauthenticated");
        logoutUser();
      });
  };

  const showRulesClicked = () => {
    setShowRules(!showRules);
    setExpanded(false);
  };

  const handleClose = () => {
    setShowRules(false);
    setExpanded(false);
  };

  return (
    <Navbar
      expanded={expanded}
      collapseOnSelect
      expand="md"
      bg="light"
      fixed="top"
    >
      <Navbar.Brand
        className="navbar-brand"
        onClick={() => {
          history.push("/");
          window.location.reload();
        }}
      >
        <img
          src={DoinkIcon}
          alt="Doink Icon"
          height="30"
          width="30"
          className="d-inline-block align-top"
        />{" "}
        Doink
      </Navbar.Brand>
      <Navbar.Toggle
        onClick={() => setExpanded(expanded ? false : "expanded")}
        aria-controls="responsive-navbar-nav"
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          {user.authenticated ? (
            <>
              <Link
                className="nav-link"
                to="/shop"
                onClick={() => setExpanded(false)}
              >
                Purchase
              </Link>
              <Nav.Link onClick={showRulesClicked}>Rules</Nav.Link>
              <Link
                className="nav-link"
                to="/gameSettings"
                onClick={() => setExpanded(false)}
              >
                Game Settings
              </Link>
              <Link
                className="nav-link"
                to="/feedback"
                onClick={() => setExpanded(false)}
              >
                Submit Feedback
              </Link>
              <Link
                className="nav-link"
                to="/cardIdea"
                onClick={() => setExpanded(false)}
              >
                Submit Card Idea
              </Link>
              <Nav.Link onClick={signOut}>Logout</Nav.Link>
            </>
          ) : (
            <Link
              className="nav-link"
              to="/shop"
              onClick={() => setExpanded(false)}
            >
              Purchase
            </Link>
          )}
        </Nav>
      </Navbar.Collapse>
      <Rules visible={showRules} handleClose={handleClose} />
    </Navbar>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapActionsToProps)(DoinkNavbar);
