import React, { lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import { Button, Container, Spinner, Toast } from "react-bootstrap";
import axios from "axios";
import jwtDecode from "jwt-decode";

// Redux
import { connect } from "react-redux";
import {
  logoutUser,
  getUserData,
  setUnauthenticated,
  setAuthenticatedUser,
  loadingUser,
  setAuthenticated,
} from "./redux/user/user.actions";
import { setLoginMessage } from "./redux/login/login.actions";
import { setServiceWorkerUpdate } from "./redux/serviceWorker/serviceWorker.actions";
import store from "./redux/store";

// Components
import DoinkNavbar from "./components/DoinkNavbar";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import LoginModal from "./components/LoginModal";

// Auth
import { auth } from "./util/firebase";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import config from "./environment/environment";
const stripePromise = loadStripe(config.stripePublishKey);
// Axios
axios.defaults.baseURL = config.apiURL;

//Get Authentication token from localStorage object
const token = localStorage.firebaseIdToken;
//Check if the token exists
//If the token exists, then decode the token
if (token) {
  const decodedToken = jwtDecode(token);
  // Check if the decoded token has expired or not
  if (decodedToken.exp * 1000 < Date.now()) {
    //Logout User
    store.dispatch(logoutUser());
    //Redirect user to the login page
    window.location.href = "/";
  } else {
    store.dispatch(setAuthenticated);
    axios.defaults.headers.common["Authorization"] = token;
    store.dispatch(getUserData());
  }
}

// Lazy Routes
const Welcome = lazy(() => import("./pages/Welcome"));
const GuestLobby = lazy(() => import("./pages/GuestLobby"));
const HostLobby = lazy(() => import("./pages/HostLobby"));
const Lobby = lazy(() => import("./pages/Lobby"));
const Game = lazy(() => import("./pages/Game"));
const Shop = lazy(() => import("./pages/Shop"));
const Feedback = lazy(() => import("./pages/Feedback"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./pages/TermsOfService"));
const DataDeletion = lazy(() => import("./pages/DataDeletion"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const CardIdea = lazy(() => import("./pages/CardIdea"));
const GameSettings = lazy(() => import("./pages/GameSettings"));

class App extends React.Component {
  unsubscribeFromAuth = null;

  constructor() {
    super(...arguments);

    this.state = {
      upToDate: true,
    };
  }

  componentDidMount() {
    const {
      setAuthenticatedUser,
      setUnauthenticated,
      loadingUser,
      setLoginMessage,
    } = this.props;

    // Be on the lookout for login issues
    auth
      .getRedirectResult()
      .then(() => {
        setLoginMessage("");
      })
      .catch((error) => {
        setLoginMessage(error.message);
      })
      .finally(() => {
        loadingUser(false);
      });

    // Get user
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (fbUser) => {
      if (fbUser) {
        const token = await fbUser.getIdToken();
        setAuthenticatedUser(token);
      } else {
        setUnauthenticated();
      }
    });

    // Make sure running the latest version
    axios
      .get("/getVersion")
      .then((versionResp) => {
        const latestVersion = versionResp.data;
        this.setState({
          upToDate: latestVersion.APP_VERSION === config.APP_VERSION,
        });
      })
      .catch((err) => {
        this.setState({ upToDate: false });
        console.error("Error checking app version");
      });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  updateServiceWorker = () => {
    const { serviceWorkerRegistration, setServiceWorkerUpdate } = this.props;

    auth.signOut().finally(() => {
      // Clear everything
      localStorage.clear();
      sessionStorage.clear();

      if (serviceWorkerRegistration) {
        const registrationWaiting = serviceWorkerRegistration.waiting;
        // Clear out service worker so update message goes away once reload finishes
        setServiceWorkerUpdate(null);
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });
        registrationWaiting.addEventListener("statechange", (e) => {
          window.location.reload();
        });
      } else {
        // Remove old cache
        caches.keys().then((names) => {
          const deletePromises = [];
          for (let name of names) {
            console.log("Cache name");
            deletePromises.push(caches.delete(name));
          }
          if (deletePromises.length > 0) {
            Promise.all(deletePromises)
              .then(() => {
                console.log("Successfully cleared cache");
              })
              .catch((err) => {
                console.error("Error clearing cache");
                console.error(err);
              })
              .finally(() => window.location.reload());
          } else {
            window.location.reload();
          }
        });
      }
    });
  };

  render() {
    const {
      user,
      loading,
      serviceWorkerUpdated,
      serviceWorkerOffline,
    } = this.props;
    return (
      <BrowserRouter forceRefresh={false}>
        <DoinkNavbar />
        {loading && (
          <div id="overlay">
            <Spinner animation="border" variant="primary" />
            <h3>Loading... Please wait.</h3>
          </div>
        )}
        <Container className="app-container">
          <Suspense
            fallback={
              <div id="overlay">
                <Spinner animation="border" variant="primary" />
                <h3>Loading... Please wait.</h3>
              </div>
            }
          >
            {(serviceWorkerUpdated || !this.state.upToDate) && (
              <Toast>
                <Toast.Header closeButton={false}>
                  <div className="update-toast">
                    <strong>An update is available.</strong>
                    <div>
                      Please press the update button below, close out of the
                      app, and then reopen the game to prevent things from
                      ending up in a weird state.
                    </div>
                  </div>
                </Toast.Header>
                <Toast.Body>
                  <Button onClick={this.updateServiceWorker}>Update</Button>
                </Toast.Body>
              </Toast>
            )}
            {serviceWorkerOffline && (
              <div className="offlineWarning">
                Offline. App might not behave properly.
              </div>
            )}
            <Switch>
              <Route exact path="/" component={Welcome} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/terms-of-service"
                component={TermsOfService}
              />
              <Route exact path="/data-deletion" component={DataDeletion} />
              <AuthenticatedRoute
                exact
                path="/host"
                component={HostLobby}
                appProps={!!user.authenticated}
              />
              <AuthenticatedRoute
                exact
                path="/guest/:lobbyId"
                component={GuestLobby}
                appProps={!!user.authenticated}
              />
              <AuthenticatedRoute
                exact
                path="/guest"
                component={GuestLobby}
                appProps={!!user.authenticated}
              />
              <AuthenticatedRoute
                path="/lobby/:lobbyId"
                component={Lobby}
                appProps={!!user.authenticated}
              />
              <AuthenticatedRoute
                exact
                path="/lobby"
                component={Lobby}
                appProps={!!user.authenticated}
              />
              <AuthenticatedRoute
                path="/game/:gameId"
                component={Game}
                appProps={!!user.authenticated && !!user.uid}
              />
              <AuthenticatedRoute
                exact
                path="/feedback"
                component={Feedback}
                appProps={!!user.authenticated}
              />
              <AuthenticatedRoute
                exact
                path="/cardIdea"
                component={CardIdea}
                appProps={!!user.authenticated}
              />
              <AuthenticatedRoute
                exact
                path="/gameSettings"
                component={GameSettings}
                appProps={!!user.authenticated}
              />
              <Elements stripe={stripePromise}>
                <Route exact path="/shop" component={Shop} />
              </Elements>
              <Route component={PageNotFound} />
            </Switch>
          </Suspense>
        </Container>
        <LoginModal />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userReducer,
  loading: state.loadingReducer.loading,
  serviceWorkerUpdated: state.serviceWorkerReducer.serviceWorkerUpdated,
  serviceWorkerRegistration:
    state.serviceWorkerReducer.serviceWorkerRegistration,
  serviceWorkerOffline: state.serviceWorkerReducer.serviceWorkerOffline,
});

const mapActionsToProps = {
  setAuthenticatedUser,
  logoutUser,
  setLoginMessage,
  setUnauthenticated,
  getUserData,
  loadingUser,
  setServiceWorkerUpdate,
};

export default connect(mapStateToProps, mapActionsToProps)(App);
