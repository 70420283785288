import React from "react";
import { Modal, Button } from "react-bootstrap";
import { signInWithGoogle, signInWithFacebook } from "../util/firebase";
import GoogleLogo from "../images/google-logo.png";
import FacebookLogo from "../images/facebook-logo.png";
import DoinkLogo from "../images/Doink_Logo_Color.png";
import "./LoginModal.css";

// Redux
import { connect } from "react-redux";
import { setLogin } from "../redux/login/login.actions";
import { loadingUser } from "../redux/user/user.actions";

function LoginModal({ setLogin, login, loadingUser }) {
  const handleClose = () => setLogin(false);

  const googleLogin = () => {
    sessionStorage.setItem("loadingUser", true);
    loadingUser(true);
    signInWithGoogle();
  };

  const facebookLogin = () => {
    sessionStorage.setItem("loadingUser", true);
    loadingUser(true);
    signInWithFacebook();
  };

  return (
    <Modal
      className="login-container"
      show={login}
      onHide={handleClose}
      centered
      animation={false}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h2>Doink Disc Golf Login</h2>
        <div className="modal__image">
          <img
            className="login-header__image"
            src={DoinkLogo}
            alt="Doink Logo"
          />
        </div>
        <Button
          className="login-button"
          variant="primary"
          size="lg"
          onClick={googleLogin}
          block
        >
          Login with <img src={GoogleLogo} alt="Google Logo" />
        </Button>
        <Button
          className="login-button"
          variant="primary"
          size="lg"
          onClick={facebookLogin}
          block
        >
          Login with <img src={FacebookLogo} alt="Facebook Logo" />
        </Button>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = ({ loginReducer: { login } }) => ({
  login: login,
});

const mapActionsToProps = {
  setLogin,
  loadingUser,
};

export default connect(mapStateToProps, mapActionsToProps)(LoginModal);
