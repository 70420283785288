import React from "react";
import { Modal } from "react-bootstrap";
import "./Rules.css";

function Rules({ visible, handleClose }) {
	return (
		<Modal
			className="rules-container"
			show={visible}
			onHide={handleClose}
			centered
			animation={false}
		>
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<h2>Doink Card Rules</h2>
				<p>
					Before playing a round of Doink Cards, your group must agree on one of
					the following options to determine how often you can play cards
					against your opponents.
				</p>
				<div className="rule__options">
					<h5>Option 1: One Card Against One Person Per Hole</h5>
					<p className="hint">The least chaotic mode.</p>
					<p>
						You are allowed to play multiple rule cards throughout a hole.
						However, an individual can only have one rule card played against
						them on a single hole.
					</p>
					<h5>Option 2: One Card Per Person Per Throw</h5>
					<p className="hint">Good mix of Doink Cards and Disc Golf.</p>
					<p>
						You are allowed to play multiple rule cards throughout a hole.
						However, an individual can only have one rule card played against
						them on a single throw.
					</p>
					<h5>Option 3: No Limit</h5>
					<p className="hint">
						Most chaotic. Warning: You might hit a few triple bogeys!
					</p>
					<p>
						There is no limit on the number of cards that can be played against
						a player on each throw.
					</p>
				</div>
				<hr />
				<h5>Doink Card Game Modes</h5>
				<label>Survivor 🌴🔥🌊</label>
				<p>
					The host picks how many cards each player gets for this entire round.
					Use your cards wisely because you won't be able to draw any more! (Max
					of 15 cards)
				</p>
				<label>Helping Hand ✋🤞🖐</label>
				<p>
					Each player starts with 2 cards. The loser(s) of each hole are allowed
					to draw one more card to help them out during their struggle.
				</p>
				<label>Infinite Twos ♾️🃏</label>
				<p>
					Each player will always have 2 cards. When a card is played, a new
					card will replace it.
				</p>
				<label>Top 5 🔝✋🗑️✌️</label>
				<p>
					Each player will receive 7 cards. Before the round starts, all players
					must get rid of their 2 least favorite cards for a total of 5 cards.
				</p>
				<label>Bottoms Up 🍑🔺</label>
				<p>
					Each player starts with 1 card. After each hole, all players get to
					draw a card except the player(s) with the lowest overall score. After
					all the new cards are drawn, the player(s) with the lowest overall
					score pick one player to discard a card.
				</p>
				<label>Mystery Box 🔮🎁</label>
				<p>
					Each player starts with 4 mystery cards. The rules of each card are
					hidden until you play the card. There is an 80% chance that a card
					will help you and there is a 20% chance that the card can be used
					against you by an opponent. If you lose a round, draw another mystery
					card.
				</p>
				<label>Brawl 🤼‍♂️🤜🤛</label>
				<p>
					Each player receives 3 card that they can use against their opponents,
					2 cards they can use to help themselves, and 1 mystery card. Use your
					cards wisely because you won't be able to draw any more!
				</p>
				<label>Backfire 🔙🔥</label>
				<p>
					Each player starts off with 5 cards. Every time you play a card
					against an opponent, there is a 50% chance that the card will back
					fire and you will have to use it against yourself.
				</p>
				<hr />
				<h5>Card Disputes</h5>
				<p>
					When there is a dispute over a rule card, everyone votes to determine
					how the rule card shall be played out. The majority vote determines
					how the card shall be played.
				</p>
				<hr />
				<h5>Failure To Complete Challenge</h5>
				<p>
					If a player fails to complete a challenge, add a stroke to their
					score.
				</p>
			</Modal.Body>
		</Modal>
	);
}

export default Rules;
