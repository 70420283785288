import axios from "axios";
import { UserActionTypes } from "./user.types";
import { LoginActionTypes } from "../login/login.types";

export const setUser = (user) => (dispatch) => {
  console.log("user", user);
  dispatch({
    type: UserActionTypes.SET_USER,
    payload: user,
  });
};

export const setAuthenticated = {
  type: UserActionTypes.SET_AUTHENTICATED,
};

export const loadingUser = (loading) => (dispatch) => {
  sessionStorage.setItem("loadingUser", loading);
  dispatch({
    type: UserActionTypes.LOADING_USER,
    payload: loading,
  });
};

export const setUserGameSettings = (gameSettings) => (dispatch) => {
  dispatch({
    type: UserActionTypes.SET_USER_GAME_SETTINGS,
    payload: gameSettings,
  });
};

export const setAuthenticatedUser = (token) => (dispatch) => {
  setAuthorizationHeader(token);
  dispatch(getUserData());
};

export const getUserData = () => (dispatch) => {
  dispatch({ type: UserActionTypes.LOADING_USER });
  axios
    .get("/user")
    .then((response) => {
      dispatch({ type: UserActionTypes.SET_USER, payload: response.data });
    })
    .catch((err) => {
      dispatch({ type: UserActionTypes.SET_UNAUTHENTICATED });

      // Report error if user didn't provide facebook email
      if (err?.response?.data?.message) {
        dispatch({
          type: LoginActionTypes.SET_LOGIN_MESSAGE,
          payload: err?.response?.data?.message,
        });
      } else {
        dispatch({
          type: LoginActionTypes.SET_LOGIN_MESSAGE,
          payload:
            "Something went wrong during the login process. Please try again. If this issue continues to occur, please contact: BrandonHancock@doinkdiscgolf.com",
        });
      }
    });
};

export const setUnauthenticated = () => (dispatch) => {
  dispatch({
    type: UserActionTypes.SET_UNAUTHENTICATED,
  });
};

const setAuthorizationHeader = (token) => {
  const firebaseIdToken = `Bearer ${token}`;
  //Store the token in the localStorage of the browser
  localStorage.setItem("firebaseIdToken", firebaseIdToken);
  //Add the token to the headers so it can be used in other
  //actions where authorization checks are required to be done
  axios.defaults.headers.common["Authorization"] = firebaseIdToken;
};

//Action to Logout a User
export const logoutUser = () => (dispatch) => {
  localStorage.setItem("forceLoggedOut", true);
  sessionStorage.removeItem("loadingUser");
  //Remove the token from the localStorage
  localStorage.removeItem("firebaseIdToken");
  //Remove the token from the Authorization headers
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: UserActionTypes.SET_UNAUTHENTICATED });
};
